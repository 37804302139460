import React from 'react';
import { Col, Row, OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import Bedclothes from '../../Assets/ServiceIcons/Bedclothes.svg';
import Meal from '../../Assets/ServiceIcons/Meal.svg';
import Pets from '../../Assets/ServiceIcons/HasPlacesNearPets.svg';
import RestaurantCarOrBuffet from '../../Assets/ServiceIcons/RestaurantCarOrBuffet.svg';
import InfotainmentService from '../../Assets/ServiceIcons/InfotainmentService.svg';
import '../../Styles/Components/Train/TrainInfo.scss';
import { TrainTimeTooltip } from './TrainTimeTooltip';

export const TrainInfo = ({ trainData, responseReservation, currentCar, additionalInfo }) => {
  const getDate = data => {
    const date = data.split('T')[0].slice(5).split('-');

    return [date[1], date[0]].join('.');
  };

  const getTime = time => {
    return time.split('T')[1].slice(0, -3);
  };

  const getTripDuration = duration => {
    const hours = Math.trunc(duration / 60);
    const minutes = duration - hours * 60;

    return (
      <>
        {hours} ч {minutes} мин
      </>
    );
  };

  const capitalizeLetters = string => {
    if (string) {
      let capitalizedString = string
        .toLowerCase()
        .split(/ |-/)
        .map(word => {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('-');
      return capitalizedString;
    }
    return string;
  };

  return (
    <Col className="train__info">
      <Row className="train__main">
        <Col className="train__main-number">
          <>
            {trainData.DisplayTrainNumber} {capitalizeLetters(trainData.TrainName)}
          </>
        </Col>
        <Col className="train__main-path">
          {responseReservation ? (
            <>{responseReservation.ReservationResults[0].Carrier}</>
          ) : (
            <>{trainData.CarrierDisplayNames.join(', ')}</>
          )}
          <>{` ${capitalizeLetters(trainData.InitialStationName)} - ${capitalizeLetters(
            trainData.FinalStationName,
          )}`}</>
        </Col>
        <Col className="train__main-electronic-registration">
          {currentCar !== undefined
            ? currentCar.HasElectronicRegistration
              ? 'ЭР'
              : 'Нет ЭР'
            : trainData.HasElectronicRegistration
            ? 'ЭР'
            : 'Нет ЭР'}
        </Col>
        {trainData.TrainDescription === 'В-СКОР' ||
        trainData.TrainDescription === 'СК' ||
        trainData.TrainDescription === 'СКРСТ' ? (
          <Col className="train__main-description">Скоростной</Col>
        ) : trainData.TrainDescription === 'СК ФИРМ' ? (
          <Col className="train__main-description">Скорый Фирменный</Col>
        ) : trainData.TrainDescription === 'ФИРМ' ? (
          <Col className="train__main-description">Фирменный</Col>
        ) : trainData.TrainDescription === 'ПАСС' ? (
          <Col className="train__main-description">Пассажирский</Col>
        ) : trainData.TrainDescription === 'Скорый с местами' ? (
          <Col className="train__main-description">Скорый с местами</Col>
        ) : (
          <></>
        )}
      </Row>
      <Row className="train__departure-arival">
        <Row className="train__departure-arival-time-info">
          <OverlayTrigger
            data-trigger="hover"
            placement={'top'}
            overlay={TrainTimeTooltip({
              time: getTime(trainData.DepartureDateTime),
              date: getDate(trainData.DepartureDateTime),
            })}
          >
            <Col className="train__departure-arival-departure">
              <Col className="train__departure-arival-departure-time train__departure-arival-time">
                <>{getTime(trainData.LocalDepartureDateTime)}</>
              </Col>
              <Col className="train__departure-arival-departure-date train__departure-arival-date">
                <>{getDate(trainData.LocalDepartureDateTime)}</>
              </Col>
            </Col>
          </OverlayTrigger>
          <Col className="train__departure-arival-time-trip-duration">{getTripDuration(trainData.TripDuration)}</Col>
          <OverlayTrigger
            data-trigger="hover"
            placement={'top'}
            overlay={TrainTimeTooltip({
              time: getTime(trainData.ArrivalDateTime),
              date: getDate(trainData.ArrivalDateTime),
            })}
          >
            <Col className="train__departure-arival-arrival">
              <Col className="train__departure-arival-arrival-time train__departure-arival-time">
                <>{currentCar ? getTime(currentCar.LocalArrivalDateTime) : getTime(trainData.LocalArrivalDateTime)}</>
              </Col>
              <Col className="train__departure-arival-arrival-date train__departure-arival-date">
                <>{getDate(trainData.LocalArrivalDateTime)}</>
              </Col>
            </Col>
          </OverlayTrigger>
        </Row>
        <Row className="train__departure-arival-stations-services">
          <Col className="train__departure-arival-stations">
            <Col className="train__departure-arival-stations-departure">
              <>{capitalizeLetters(trainData.OriginStationName)}</>
            </Col>
            <Col className="train__departure-arival-stations-arrival">
              <>{capitalizeLetters(trainData.DestinationStationName)}</>
            </Col>
          </Col>
          <Col className="train__departure-arival-services">
            {trainData.CarServices.map((item, i) =>
              item === 'Meal' ? (
                <div className="services__img" key={i}>
                  <img src={Meal} alt="meal" key={item} />
                  <span className="services__img-description">Питание</span>
                </div>
              ) : item === 'RestaurantCarOrBuffet' ? (
                <div className="services__img" key={i}>
                  <img src={RestaurantCarOrBuffet} alt="RestaurantCarOrBuffet" key={item} />
                  <span className="services__img-description">Вагон-ресторан или буфет</span>
                </div>
              ) : item === 'InfotainmentService' ? (
                <div className="services__img" key={i}>
                  <img src={InfotainmentService} alt="InfotainmentService" key={item} />
                  <span className="services__img-description">ИРС(Информационно-развлекательный центр)</span>
                </div>
              ) : item === 'Bedclothes' ? (
                <div className="services__img" key={i}>
                  <img src={Bedclothes} alt="Bedclothes" key={item} />
                  <span className="services__img-description">Постельное бельё</span>
                </div>
              ) : (
                <></>
              ),
            )}
            {trainData.CarGroups[0].HasPlacesNearPets ? (
              <div className="services__img">
                <img src={Pets} alt="pets" />
                <span className="services__img-description">В некоторых вагонах возможна перевозка животных</span>
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="train__departure-arival-description">
          {/* <span>На карточке указано местное время</span> */}
          {additionalInfo && <span>{additionalInfo}</span>}
          {trainData.HasDynamicPricingCars && <span>Динамическое ценообразование</span>}
        </Row>
      </Row>
    </Col>
  );
};
