import React, {useEffect, useState} from "react";
import {useStore} from "effector-react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {CarAndPlacesTable} from "../Components/ReservationPage/CarAndPlacesTable";
import {Timer} from "../Components/ReservationPage/Timer";
import {CustomModal} from "../Components/CustomModal/CustomModal";
import {
    $localTrainInfoFromStore,
    $localTrainInfoReturnFromStore,
    errorHandling,
    resetCustomers,
} from "../Services/trainsService";
import "../Styles/Pages/ReservationPage.scss";
import {TrainInfo} from "../Components/Train/TrainInfo";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import {baseUrl} from "../config";
import cross from "../Assets/cross.png";

export const ReservationPage = () => {
    const location = useLocation();
    const responseReservation = location.state.responseReservation;
    const [isLoaded, setIsLoaded] = useState(false);
    const [modal, setOpenModal] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const navigate = useNavigate();
    const trainData = useStore($localTrainInfoFromStore);
    const [agreement, setAgreement] = useState(false);
    const trainReturnData = useStore($localTrainInfoReturnFromStore);
    const [backPagesAmount, setBackPagesAmount] = useState(-1);

    //Функция отправки запроса на создание бронирования
    // const createReservation = () => {
    //   const json = JSON.stringify(location.state.params);
    //   try {
    //     axios
    //       .post(`https://${baseUrl}/api/Railway/create_reservation`, json, {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       })
    //       .then((response) => {
    //         setResponseReservation(response.data);
    //         setIsLoaded(true);
    //       })
    //       .catch((error) => {
    //         resetCustomers();
    //         errorHandling(error);
    //         navigate(-1);
    //       });
    //   } catch (error) {
    //     throw error;
    //   }
    // };

    const onCloseModalHandler = () => {
        setOpenModal(false);
        navigate("/");
    };

    const cancelReservation = () => {
        const json = JSON.stringify({OrderId: responseReservation.OrderId});
        try {
            axios
                .post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    setOpenModal(true);
                });
        } catch (error) {
            throw error;
        }
    };

    const cancelReservationByButton = () => {
        setModalCancel(true);
        const json = JSON.stringify({OrderId: responseReservation.OrderId});
        window.removeEventListener("popstate", handleBackButton);
        setBackPagesAmount(-2);

        try {
            axios.post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            throw error;
        }
    };

    const handleBackButton = (event) => {
        setModalCancel(true);
        event.preventDefault();
        const json = JSON.stringify({OrderId: responseReservation.OrderId});

        try {
            axios.post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        window.addEventListener("popstate", handleBackButton);

        // Сохраняем состояние истории браузера перед тем, как покинуть компонент
        window.history.pushState({source: "ReservationPage"}, "");

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    return (
        <div className="reservation-submit">
            <div className="reservation-submit__timer">
                <div className="reservation-submit__timer-content">
                    <Timer
                        confirmTill={responseReservation.ConfirmTill}
                        onTimeIsOver={cancelReservation}
                    />
                </div>
            </div>

            <ProgressBar/>
            <div className="reservation-submit__content">
                <>
                    <div className="reservation-submit__content-departure-train-info">
                        <h2>Отправление</h2>
                        {trainReturnData === "" ? (
                            <TrainInfo
                                trainData={trainData}
                                responseReservation={responseReservation}
                                additionalInfo={
                                    responseReservation.ReservationResults[0]
                                        .AdditionalInformation
                                }
                            />
                        ) : (
                            <>
                                <h2>Туда:</h2>
                                <TrainInfo
                                    trainData={trainData}
                                    responseReservation={responseReservation}
                                    additionalInfo={
                                        responseReservation.ReservationResults[0]
                                            .AdditionalInformation
                                    }
                                />
                                <h2>Обратно:</h2>
                                <TrainInfo
                                    trainData={trainReturnData}
                                    additionalInfo={
                                        responseReservation.ReservationResults[1]
                                            .AdditionalInformation
                                    }
                                />
                                <div>
                                    {responseReservation.ReservationResults.some((result) =>
                                        result.Blanks.some((blank) => blank.TariffInfo.DisplayName === "ОБРТ")
                                    ) && (
                                        <div className="reservation-page__limitation">
                                            <h3>Обратите внимание</h3>
                                            <p>
                                                {`Возврат билета по направлению ${trainData.InitialTrainStationName} - ${trainData.DestinationStationName} возможен только после возврата билета ${trainReturnData.InitialTrainStationName} - ${trainReturnData.DestinationStationName}`}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="reservation-submit__content-departure-info">
                        <CustomModal
                            open={modal}
                            onOpen={setOpenModal}
                            onClose={onCloseModalHandler}
                        >
                            <h2>Заказ №{responseReservation.OrderId} был отменен!</h2>
                            <h4>Срок подтверждения заказа истёк.</h4>
                            <h4>Вы будете перенаправлены на главную страницу.</h4>
                        </CustomModal>
                        <CustomModal
                            open={modalCancel}
                            onOpen={setModalCancel}
                            onClose={() => {
                                setModalCancel(false);
                                navigate(backPagesAmount);
                            }}
                        >
                            <h2>Заказ №{responseReservation.OrderId} был отменен!</h2>

                            <h4>Вы будете перенаправлены на предыдущую страницу.</h4>
                        </CustomModal>
                        <Row className="reservation-submit__content-table">
                            <h2>Вагон и места</h2>
                            {responseReservation.ReservationResults.map(
                                (reservationResult, i) => (
                                    <CarAndPlacesTable
                                        key={i}
                                        reservationResult={reservationResult}
                                        customers={responseReservation.Customers}
                                    />
                                )
                            )}
                        </Row>
                        <h2>Контактная информация</h2>
                        <Row className="reservation-submit__content-departure-info-contacts">
                            <Col>
                                <h5>Мобильный телефон</h5>
                                <p>{responseReservation.OrderContacts.ContactPhone}</p>
                            </Col>
                            <Col>
                                <h5>E-mail</h5>
                                <p>{responseReservation.OrderContacts.ContactEmail}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    label={`Даю согласие на обработку персональных данных`}
                                    checked={agreement}
                                    onClick={() => setAgreement(!agreement)}
                                />
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Col className="reservation-submit__content-payment-block">
                                <Col className="d-flex justify-content-end">
                                    <Col className="reservation-submit__content-payment-block-price">
                                        <p>Билетов: {responseReservation.Customers.length}</p>
                                        <p>{responseReservation.Amount}₽</p>
                                    </Col>
                                </Col>
                                <Col className="reservation-submit__content-payment-block-btn">
                                    <Button
                                        className="reservation-submit__content-cancel-btn"
                                        role="button"
                                        onClick={() => cancelReservationByButton()}
                                    >
                                        Отменить бронирование
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            window.location.replace(
                                                `${baseUrl}/pay/Railway/${responseReservation.OrderId}`
                                            )
                                        }
                                        className={
                                            agreement
                                                ? "reservation-submit__content-pay-btn"
                                                : "reservation-submit__content-pay-btn reservation-submit__content-pay-btn_disabled"
                                        }
                                        role="button"
                                    >
                                        Оплатить
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </>
            </div>
        </div>
    );
};
