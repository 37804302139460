import { Popover, PopoverBody } from 'react-bootstrap';

export const TrainTimeTooltip = props => {
  const { time, date } = props;

  return (
    <Popover id="popover-basic">
      <PopoverBody>
        <span>На карточке указано местное время.</span>
        <br />
        <span>Московское время - {time}</span>
        <br />
        <span>Дата: {date}</span>
      </PopoverBody>
    </Popover>
  );
};
